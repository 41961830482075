html,
body {
  padding: 0;
  margin: 0;
}

body {
  background-color: #f7f7f7 !important;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
:root {
  font-size: 16px;
}
button {
  outline: none !important;
}

#profile:hover ~ #profileList {
  display: block;
}
#profileList {
  display: none;
}

#profileList:hover {
  display: block;
}

#activeLang:hover ~ #language {
  display: block;
}
#language:hover {
  display: block;
}
.language {
  display: none !important;
}
#language {
  display: none;
}

/* Components */
#articles .carousel .slide {
  background-color: unset !important;
}
#articles .control-dots {
  top: 100% !important;
}
#articles .carousel .thumbs-wrapper {
  display: none !important;
}
#articles .carousel-status {
  display: none !important;
}

.border-primary {
  border: 1px solid #2673dd;
}
.w-33 {
  width: 32%;
}
.w-34 {
  width: 36%;
}
/* Home */
.text-secondary {
  color: #db7f3e;
}
.text-primary {
  color: #2673dd !important;
}
.bg-primary {
  background-color: #2673dd !important;
}

.bg-popup {
  background-color: #e5e5e5 !important;
}

.opacity-dark {
  opacity: 0.9;
}

.list {
  border-bottom: 0.125rem solid #fff;
}
.list:hover {
  border-bottom: 0.125rem solid #1c1c1c;
  transition: all 0.3s ease 0s;
  text-decoration: none;
}

@media (max-width: 900px) {
  .md-w-content {
    width: fit-content;
  }
}

.w-content {
  width: fit-content;
}

@media (max-width: 320px) {
  .bg-unset {
    background: unset !important;
  }
}

.z-modals {
  z-index: 1500;
}
.z-modal-2 {
  z-index: 1600;
}
.w-300 {
  width: 300px;
}
.top-lang {
  top: 68px;
  left: 58%;
}
/* Home */

/* Rooms */

.w-55 {
  width: 55%;
}
.w-45 {
  width: 45%;
}
.bg-see {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
}
.bg-see-dark {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9));
}
.-mt-see {
  margin-top: -6.2rem;
  cursor: pointer;
}
.text-green {
  color: #44ab4a;
}
.text-reds {
  color: #ee4746;
}

::placeholder {
  color: #cbd5e0 !important;
  opacity: 1; /* Firefox */
}
.rotate-up {
  transform: rotateX(180deg);
}
.phone-dial {
  margin-top: 19rem;
  z-index: 999 !important;
}

.h-logindivide {
  height: 400px;
}
.footer {
  background-color: #071433 !important;
}
.pfooter {
  color: white;
}
.p2footer {
  color: rgba(193, 193, 193, 1);
}
.newsteteller-nav {
  background: #52575c;
}
.submit-but {
  background: #2673dd !important;
  padding-left: 2px !important;
}

@media (min-width: 3000px) {
  :root {
    font-size: 50px !important;
  }
  .m-top {
    margin-top: -1.5rem !important;
  }
}
@media (min-width: 2048px) {
  :root {
    font-size: 26px;
  }
  .m-top {
    margin-top: -1.5rem !important;
  }
}
.MuiPickersToolbar-toolbar {
  background-color: #2673dd !important;
}
.MuiPickersDay-daySelected {
  background-color: #2673dd !important;
}
.MuiButton-label {
  color: #2673dd !important;
}
